/*!
 * Start Bootstrap - Agency v3.3.7+1 (http://startbootstrap.com/template-overviews/agency)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */
body {
  overflow-x: hidden;
  font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.text-muted {
  color: #000000;
  font-weight: bold;
}

.text-primary {
  color: #bf090d;
}

p {
  font-size: 14px;
  line-height: 1.75;
}

p.large {
  font-size: 16px;
}

a,
a:hover,
a:focus,
a:active,
a.active {
  outline: none;
}

a {
  color: #bf090d;
}

a:hover,
a:focus,
a:active,
a.active {
  color: #bf090d;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.img-centered {
  margin: 0 auto;
}

.bg-darkest-gray {
  background-color: #222222;
}

.btn-primary {
  background-color: #bf090d;
  border-color: #bf090d;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  border-radius: 5px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: white;
  background-color: #ccc;
  border-color: #bf090d;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #bf090d;
  border-color: #bf090d;
}

.btn-primary .badge {
  color: #bf090d;
  background-color: white;
}

.btn-xl {
  color: white;
  background-color: #bf090d;
  border-color: #bf090d;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 20px;
  border-radius: 5px;
}

.btn-sm {
  color: white;
  background-color: #bf090d;
  border-color: #bf090d;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
}

.main {
  margin-top: -100px;
  z-index: 1;
}

.btn-xl:hover,
.btn-xl:focus,
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
  color: #bf090d;
  background-color: #fff;
  border-color: #fff;
  border-radius: 5px;
}

.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
  background-image: none;
  border-radius: 5px;
}

.btn-xl.disabled,
.btn-xl[disabled],
fieldset[disabled] .btn-xl,
.btn-xl.disabled:hover,
.btn-xl[disabled]:hover,
fieldset[disabled] .btn-xl:hover,
.btn-xl.disabled:focus,
.btn-xl[disabled]:focus,
fieldset[disabled] .btn-xl:focus,
.btn-xl.disabled:active,
.btn-xl[disabled]:active,
fieldset[disabled] .btn-xl:active,
.btn-xl.disabled.active,
.btn-xl[disabled].active,
fieldset[disabled] .btn-xl.active {
  background-color: #bf090d;
  border-color: #bf090d;
  border-radius: 5px;
}

.btn-xl .badge {
  color: #bf090d;
  background-color: white;
  border-radius: 5px;
}

.navbar-custom {
  background-color: #222222;
  border-color: transparent;
}

.navbar-custom .navbar-brand {
  color: #bf090d;
  font-family: "Kaushan Script", "Helvetica Neue", Helvetica, Arial, cursive;
}

.navbar-custom .navbar-brand:hover,
.navbar-custom .navbar-brand:focus,
.navbar-custom .navbar-brand:active,
.navbar-custom .navbar-brand.active {
  color: #fec503;
}

.navbar-custom .navbar-collapse {
  border-color: rgba(255, 255, 255, 0.02);
}

.navbar-custom .navbar-toggle {
  background-color: #bf090d;
  border-color: #bf090d;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: white;
  font-size: 12px;
}

.navbar-custom .navbar-toggle:hover,
.navbar-custom .navbar-toggle:focus {
  background-color: #bf090d;
}

.navbar-custom .nav li a {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #fff;
  font-size: 14px;
  padding: 5px 15px;
}

.navbar-custom .nav li a:hover,
.navbar-custom .nav li a:focus {
  color: #bf090d;
  outline: none;
}

.navbar-custom .navbar-nav > .active > a {
  border-radius: 0;
  color: white;
  background-color: #bf090d;
}

.navbar-custom .navbar-nav > .active > a:hover,
.navbar-custom .navbar-nav > .active > a:focus {
  color: white;
  background-color: #bf090d;
}

a.btn-xs {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #bf090d !important;
  font-size: 14px;
  padding: 5px 15px;
}

.navbar-nav > li > .dropdown-menu {
  background-color: #000;
}

header {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  text-align: center;
  color: white;
}

header .intro-text {
  padding-top: 100px;
  padding-bottom: 50px;
  opacity: 1;
}

header .intro-text .intro-lead-in {
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 25px;
  margin-top: 70px;
}

header .intro-text .intro-heading {
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 25px;
  /*font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;*/
  /*text-transform: uppercase;*/
  /*font-weight: 700;*/
  /*font-size: 50px;*/
  /*line-height: 50px;*/
  /*margin-bottom: 25px;*/
}

section {
  padding: 100px 0;
}

section h2.section-heading {
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 15px;
}

section#meusDados h2.section-heading,
section#registrarDoacao h2.section-heading,
section#minhasDoacoes h2.section-heading,
section#solicitacoesDoacao h2.section-heading {
  margin-top: 30px;
}

section h3.section-subheading {
  font-size: 16px;
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: none;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 75px;
}

.service-heading {
  margin: 15px 0;
  text-transform: none;
}

#campanha .campanha-item {
  margin: 0 0 15px;
  right: 0;
}

#campanha .campanha-item .campanha-link {
  display: block;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}

#campanha .campanha-item .campanha-link .campanha-hover {
  background: rgba(191, 9, 13, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
}

#campanha .campanha-item .campanha-link .campanha-hover:hover {
  opacity: 1;
}

#campanha .campanha-item .campanha-link .campanha-hover .campanha-hover-content {
  position: absolute;
  width: 100%;
  height: 20px;
  font-size: 20px;
  text-align: center;
  top: 50%;
  margin-top: -12px;
  color: white;
}

#campanha .campanha-item .campanha-link .campanha-hover .campanha-hover-content i {
  margin-top: -12px;
}

#campanha .campanha-item .campanha-link .campanha-hover .campanha-hover-content h3,
#campanha .campanha-item .campanha-link .campanha-hover .campanha-hover-content h4 {
  margin: 0;
}

#campanha .campanha-item .campanha-caption {
  max-width: 400px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.15);
  text-align: center;
  padding: 20px;
}

#campanha .campanha-item .campanha-caption h4 {
  text-transform: none;
  margin: 0;
  font-size: 24px;
}

#campanha .campanha-item .campanha-caption p {
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 25px;
  margin: 0;
  color: #bf090d;
}

#campanha * {
  z-index: 2;
}

.timeline {
  list-style: none;
  padding: 0;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: "";
  width: 2px;
  background-color: #f1f1f1;
  left: 40px;
  margin-left: -1.5px;
}

.timeline > li {
  margin-bottom: 50px;
  position: relative;
  min-height: 50px;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li .timeline-panel {
  width: 100%;
  float: right;
  padding: 0 20px 0 100px;
  position: relative;
  text-align: left;
}

.timeline > li .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline > li .timeline-image {
  left: 0;
  margin-left: 0;
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: 100;
  background-color: #bf090d;
  color: white;
  border-radius: 100%;
  border: 7px solid #f1f1f1;
  text-align: center;
}

.timeline > li .timeline-image h4 {
  font-size: 10px;
  margin-top: 12px;
  line-height: 14px;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  text-align: left;
  padding: 0 20px 0 100px;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline > li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4 {
  margin-top: 0;
  color: inherit;
}

.timeline .timeline-heading h4.subheading {
  text-transform: none;
}

.timeline .timeline-body > p,
.timeline .timeline-body > ul {
  margin-bottom: 0;
}

.team-member {
  text-align: center;
  margin-bottom: 50px;
}

.team-member img {
  margin: 0 auto;
  border: 7px solid white;
  max-width: 180px;
  max-height: 220px;
}

.team-member h4 {
  margin-top: 25px;
  margin-bottom: 0;
  text-transform: none;
}

.team-member p {
  margin-top: 0;
}

aside.clients img {
  margin: 50px auto;
}

section#depoimentos {
  background-position: center;
  background-repeat: no-repeat;
}

section#depoimentos .section-heading {
  color: #000000;
  font-weight: bold;
}

section#depoimentos h2.section-heading {
  color: #bf090d;
  font-weight: bold;
}

section#depoimentos h2.section-heading.dep {
  color: #fff;
  font-weight: bold;
}

section#saiba-mais .text-muted,
section#aplicativo .text-muted,
section#onde-doar .text-muted,
section#contact .text-muted {
  color: white;
}

section#contact .form-group,
section#depoimentos .form-group {
  margin-bottom: 25px;
}

section#contact .form-group input,
section#contact .form-group textarea,
section#depoimentos .form-group input,
section#depoimentos .form-group textarea {
  padding: 15px;
}

section#depoimentos .form-group input[type="file"] {
  padding: 1px;
  -webkit-padding: 15px;
}

section#contact .form-group input.form-control,
section#depoimentos .form-group input.form-control {
  height: 45px;
  width: 500px;
}

section#contact .form-group textarea.form-control {
  height: 240px;
}

section#depoimentos .form-group textarea.form-control {
  height: 330px;
}

section#contact .form-control:focus,
section#depoimentos .form-control:focus {
  border-color: #bf090d;
  box-shadow: none;
}

section#contact ::-webkit-input-placeholder,
section#depoimentos ::-webkit-input-placeholder {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #ccc;
}

section#contact :-moz-placeholder,
section#depoimentos :-moz-placeholder {
  /* Firefox 18- */
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #ccc;
}

section#contact ::-moz-placeholder,
section#depoimentos ::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #ccc;
}

section#contact :-ms-input-placeholder,
section#depoimentos :-ms-input-placeholder {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #ccc;
}

section#contact .text-danger,
section#depoimentos .text-danger {
  color: #f5f5f5;
}

section#depoimentos .btn-xl {
  background-color: #fff;
  border-color: #fff;
  color: #bf090d;
  border-radius: 5px;
}

section#depoimentos .btn-xl {
  margin-bottom: 10px;
}

section#depoimentos .btn-xl:hover {
  color: #bf090d;
  background-color: #ccc;
  border-color: #ccc;
  border-radius: 5px;
}

section#contact .form-group label,
section#depoimentos .form-group label {
  display: table;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  color: white;
}

section#depoimentos .form-group input,
section#depoimentos .form-group select {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/
  padding: 10px;
  height: 45px;
  width: 500px;
}

footer {
  padding: 25px 0;
  text-align: center;
}

footer span.copyright {
  line-height: 40px;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-transform: none;
}

footer ul.quicklinks {
  margin-bottom: 0;
  line-height: 40px;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-transform: none;
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a {
  display: block;
  background-color: #bf090d;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  font-size: 20px;
  line-height: 40px;
  color: white;
  outline: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

ul.social-buttons li a:hover,
ul.social-buttons li a:focus,
ul.social-buttons li a:active {
  background-color: #555;
}

.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
  outline: none;
}

.campanha-modal .modal-dialog {
  margin: 0;
  height: 100%;
  width: auto;
}

.campanha-modal .modal-content {
  border-radius: 0;
  background-clip: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  min-height: 100%;
  padding: 10px 0;
  text-align: center;
}

.campanha-modal .modal-content h2 {
  margin-bottom: 15px;
  text-align: center;
}

.campanha-modal .modal-content p {
  margin-bottom: 10px;
  font-size: 14px;
  color: #bf090d;
}

.campanha-modal .modal-content p.item-intro {
  margin: 20px 0 30px;
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 16px;
}

.campanha-modal .modal-content ul.list-inline {
  margin-bottom: 30px;
  margin-top: 0;
  color: #bf090d;
  font-size: 30px;
}

.campanha-modal .modal-content ul.list-inline {
  margin-bottom: 30px;
  margin-top: 0;
  color: #bf090d;
}

.campanha-modal .modal-content img {
  margin-bottom: 30px;
}

.campanha-modal .close-modal {
  position: absolute;
  width: 75px;
  height: 75px;
  background-color: transparent;
  top: 5px;
  right: 25px;
  cursor: pointer;
}

.campanha-modal .close-modal:hover {
  opacity: 0.3;
}

.campanha-modal .close-modal .lr {
  height: 75px;
  width: 1px;
  margin-left: 35px;
  background-color: #222222;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari and Chrome */
  z-index: 1051;
}

.campanha-modal .close-modal .lr .rl {
  height: 75px;
  width: 1px;
  background-color: #222222;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  /* Safari and Chrome */
  z-index: 1052;
}

.campanha-modal .modal-backdrop {
  opacity: 0;
  display: none;
}

::-moz-selection {
  text-shadow: none;
  background: #bf090d;
}

::selection {
  text-shadow: none;
  background: #bf090d;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

body {
  webkit-tap-highlight-color: #bf090d;
}

.smartphone {
  background: url("../img/smartphone-preto3-min.png") center center no-repeat scroll;
  background-size: 140%;
  padding: 250px;
  padding-top: 130px;
  margin-left: -10%;
}

#imagem-header {
  background-image: url("../img/fundo-site-escuro.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  -o-background-size: 100% 100%;
  text-align: center;
  color: #bf090d;
  z-index: -1;
  padding-bottom: 120px;
}

#logo {
  position: absolute;
  top: 12px;
  /*width: 75%;*/
}

#logo img {
  width: 40% !important;
  height: auto !important;
  margin-top: 10px;
}

#logo_adm {
  position: absolute;
  top: 12px;
}

#logo_adm img {
  width: 20% !important;
  height: auto !important;
}

.img-app img {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 250px;
  height: 70px;
  margin-top: 20px;
}

.img-modal {
  width: 25%;
  height: 25%;
}

.em-breve {
  width: 100px !important;
  height: 50px !important;
  width: 20% !important;
}

.md-datepicker-calendar-pane {
  z-index: 1200;
}

.md-virtual-repeat-container {
  z-index: 1200 !important;
}

.orientacoes {
  padding-left: 90px;
  padding-right: 35px;
  padding-bottom: 50px;
}

.orientacoes h4 {
  text-transform: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  font-weight: bold;
}

.orientacoes img {
  max-width: 20px;
  max-height: 20px;
}

.img-responsive {
  display: initial;
  max-width: 90%;
  height: inherit;
  max-height: 90%;
}

section#parceiros .img-responsive {
  margin-bottom: 30px;
  height: 100px;
  width: 150px;
}

section#hemocentros-parceiros .img-responsive {
  margin-bottom: 30px;
  height: 100px;
  max-width: 180px;
}

.form-custom-pds {
  background-color: #bf090d;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  display: none;
  background-size: 700px;
}

.form-novo-doador {
  background-image: url("../img/fundo-site-claro.png");
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  display: none;
}

section {
  padding: 20px;
}

.doacao {
  background-color: #bf090d;
  background-image: url("../img/doacao4.png");
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  display: none;
  background-size: 1000px;
}

.form-custom-pds .form-group label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  color: white;
}

.form-custom-pds .section-heading {
  color: white;
}

.form-custom-pds .table-hover tbody tr:hover td {
  background: transparent;
  color: #ab0b0b;
}

.form-custom-pds .text-muted {
  color: white;
}

.form-custom-pds .form-group {
  /*margin-bottom: 25px;*/
}

.form-custom-pds .form-group input,
.form-custom-pds .form-group select,
.form-custom-pds .form-group textarea {
  /*padding: 10px;*/
}

.form-custom-pds .form-group input.form-control,
.form-custom-pds .form-group select.form-control {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/
  /*height: 45px;*/
  color: #0f0f0f;
}

.form-novo-doador .form-group label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  color: black;
}

.form-novo-doador .section-heading {
  color: white;
}

.form-novo-doador .table-hover tbody tr:hover td {
  background: transparent;
  color: #ab0b0b;
}

.form-novo-doador .text-muted {
  color: white;
}

.form-novo-doador .form-group {
  /*margin-bottom: 25px;*/
}

.form-novo-doador .form-group input,
.form-novo-doador .form-group select,
.form-novo-doador .form-group textarea {
  /*padding: 10px;*/
}

.form-novo-doador .form-group input.form-control,
.form-novo-doador .form-group select.form-control {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/
  /*height: 45px;*/
  color: #0f0f0f;
}

.multiSelect > button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  -moz-user-select: none !important;
  border-radius: 5px !important;
  color: #0f0f0f !important;
  font-size: 14px !important;
  min-height: 45px !important;
  padding: 1px 15px !important;
  position: relative !important;
  width: 100% !important;
  text-align: left !important;
  box-shadow: none !important;
  background-color: white !important;
  background-image: none !important;
}

.multiSelect > button:hover,
.multiSelect > button:active,
.multiSelect > button:focus {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-image: none !important;
  box-shadow: none !important;
}

.multiSelect .multiSelectItem {
  display: block;
  padding: 3px;
  color: #444;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  position: relative;
  width: 100% !important;
  min-height: 45px;
}

.multiSelect .inputFilter {
  border-radius: 2px !important;
  border: 1px solid #bf090d !important;
  height: 30px !important;
  font-size: 14px !important;
  width: 100%;
  padding-left: 7px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #888;
  margin: 0px 0px 8px 0px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.multiSelect .inputFilter:hover,
.multiSelect .inputFilter:active,
.multiSelect .inputFilter:focus {
  border: 1px solid #bf090d !important;
}

.multiSelect .checkBoxContainer {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  display: block;
  padding: 8px;
  overflow: hidden;
  width: 100% !important;
}

.multiSelect .multiSelectItem:hover,
.multiSelect .multiSelectGroup:hover {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-image: linear-gradient(#bf090d, #bf090d) !important;
  color: white !important;
  cursor: pointer !important;
  border: none !important;
}

.multiSelect .multiSelectItem:not(.multiSelectGroup).selected {
  background-image: linear-gradient(#bf090d, #bf090d) !important;
  color: white !important;
  cursor: pointer;
  border-top: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #d9d9d9;
}

.multiSelect .checkboxLayer {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
  display: none !important;
  position: absolute;
  width: 95%;
  z-index: 999;
}

.form-custom-pds .form-group textarea.form-control {
  height: 236px;
}

.form-custom-pds .form-group input[type="checkbox"],
.form-custom-pds .form-group input[type="radio"] {
  /*-ms-transform: scale(2); */ /* IE */ /*
    -moz-transform: scale(2);  FF */
  /*-webkit-transform: scale(1,1);  Safari and Chrome*/
  /*-o-transform: scale(2);  Opera */
  transform: scale(1.5);
  padding: 30px;
  margin-left: 5px;
  margin-right: 8px;
  margin-bottom: 10px;
  margin-top: 10px;
  line-height: 25px;
}

.form-custom-pds .form-control:focus {
  border-color: #bf090d;
  box-shadow: none;
}

.form-custom-pds ::-webkit-input-placeholder {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #ccc;
}

.form-custom-pds :-moz-placeholder {
  /* Firefox 18- */
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #ccc;
}

.form-custom-pds ::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #ccc;
}

.form-custom-pds :-ms-input-placeholder {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #ccc;
}

.form-custom-pds .text-danger {
  color: #f5f5f5;
}

.form-custom-pds .btn-xl {
  background-color: #fff;
  border-color: #fff;
  color: #bf090d;
  border-radius: 5px;
}

.form-custom-pds .btn-xl:hover {
  background-color: #ccc;
  border-color: #ccc;
  color: #bf090d;
  border-radius: 5px;
}

.multiple-select-pds {
  height: auto;
  color: #0f0f0f;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #bf090d;
  outline: 0;
}

.dropdown-menu > .li > a {
  color: #000000;
}

select option:checked,
select option:hover {
  box-shadow: 0 0 10px 100px #bf090d inset;
}

.pesquisa-onde-doar {
  display: block;
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 0px 1px;
}

.btn-pesquisa-onde-doar {
  display: block;
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #fff;
  background-color: #bf090d !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 0px 1px;
}

.pesquisa-onde-doar:hover,
.pesquisa-onde-doar:focus,
.pesquisa-onde-doar:active {
  border-color: #bf090d;
  box-shadow: none;
}

.btn-small {
  color: white;
  background-color: #bf090d;
  border-color: #bf090d;
  text-transform: uppercase;
  font-size: 13px;
  padding: 5px 10px;
  letter-spacing: 3px;
  font-weight: bold;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: 5px;
}

.btn-small:hover,
.btn-small:focus,
.btn-small:active,
.btn-small.active,
.open .dropdown-toggle.btn-small {
  color: #bf090d;
  background-color: #fff;
  border-color: #bf090d;
}

/*slider depoimentos*/
.carousel {
  width: 700px;
  background-color: #ccc0;
}

.carousel-inner {
  padding-left: 10px;
  padding-right: 130px;
  padding-bottom: 100px;
}

.carousel-inner .col-md-8 {
  margin-top: 25px;
}

.carousel-inner .small,
small {
  font-size: 100%;
}

.carousel img {
  width: 125px;
  height: 125px;
  margin-top: 20px;
}

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ccc;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #bf090d;
}

.carousel-control.left {
  left: 0;
  left: 5px;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.0001) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
  background-repeat: repeat-x;
}

.carousel-control.right {
  right: 125px;
  left: auto;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0, rgba(251, 246, 246, 0.5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
  background-repeat: repeat-x;
}

.red {
  background-color: #bf090d;
  background-image: url("../img/interview.png");
  background-size: 65%;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
}

.red .section-heading {
  color: white;
}

.red .text-muted {
  color: white;
}

.show-doacoes,
.show-solicita-doacao {
  background-color: #ffffff61;
  /*background-image: -webkit-linear-gradient(left, rgba(204, 204, 204, 0.45) 0, rgba(204, 204, 204, 0.45) 100%);
    background-image: -o-linear-gradient(left, rgba(204, 204, 204, 0.45) 0, rgba(204, 204, 204, 0.45) 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(204, 204, 204, 0.45)), to(rgba(204, 204, 204, 0.45)));
    background-image: linear-gradient(to right, rgba(204, 204, 204, 0.45) 0, rgba(204, 204, 204, 0.45) 100%);*/
  opacity: 0.85;
  padding: 10px;
  margin: 15px;
  border-radius: 20px;
}

.show-doacoes .titulo,
.show-solicita-doacao .titulo {
  text-transform: none;
  font-size: 16px;
  padding: 10px 10px;
  letter-spacing: 2px;
  font-weight: bold;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #bf090d;
}

.show-doacoes .dado,
.show-solicita-doacao .dado {
  text-transform: none;
  font-size: 16px;
  padding: 10px 10px;
  letter-spacing: 2px;
  font-weight: normal;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #0c0c0c;
}

.show-doacoes img {
  position: relative;
  float: right;
  margin-top: -100px;
  width: 70px;
  height: 70px;
}

.show-solicita-doacao img {
  position: relative;
  float: right;
  bottom: 80px;
  width: 80px;
  height: 80px;
}

.show-unidades {
  background-color: #bf090d;
  padding: 10px;
  margin: 10px;
  border-radius: 40px;
}

.show-unidades .dado {
  dotext-transform: none;
  font-size: 14px;
  padding: 10px 10px;
  letter-spacing: 2px;
  font-weight: bold;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: white;
}.num-doadores .text-doadores

section#parceiros .col-md-3 {
  width: 20%;
}

section#meusDados,
section#registrarDoacao,
section#minhasDoacoes,
section#solicitacoesDoacao {
  background-image: url("../img/fundo-site-claro.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  -o-background-size: 100% 100%;
  padding: 45px 0;
}

.navbar-brand-adm {
  line-height: 85px;
}

section#registrarDoacao .text-muted {
  color: white;
}

section#meusDados .form-group,
section#registrarDoacao .form-group {
  margin-bottom: 15px;
}

section#meusDados .form-group input,
section#meusDados .form-group textarea,
section#registrarDoacao .form-group input,
section#registrarDoacao .form-group textarea {
  padding: 10px;
}

section#meusDados .form-group input.form-control,
section#registrarDoacao .form-group input.form-control {
  height: 35px;
  width: 500px;
}

section#meusDados .form-group input,
section#registrarDoacao .form-group input {
  padding: 5px;
  height: 35px;
  width: 500px;
}

section#meusDados .form-group select,
section#registrarDoacao .form-group select {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/
  padding: 5px;
  height: 35px;
  width: 500px;
}

section#meusDados .form-group textarea.form-control,
section#registrarDoacao .form-group textarea.form-control {
  height: 250px;
}

section#registrarDoacao .form-group input[type="checkbox"],
section#meusDados .form-group input[type="checkbox"] {
  /*-ms-transform: scale(2); */ /* IE */ /*
    -moz-transform: scale(2); */ /* FF */ /*
    -webkit-transform: scale(2); */ /* Safari and Chrome */ /*
    -o-transform: scale(2);  Opera */
  transform: scale(1.5);
  padding: 30px;
  margin-left: -235px;
  margin-right: -235px;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 25px;
  line-height: 25px;
}

section#meusDados .form-control:focus,
section#registrarDoacao .form-control:focus {
  border-color: #bf090d;
  box-shadow: none;
}

section#registrarDoacao ::-webkit-input-placeholder,
section#meusDados ::-webkit-input-placeholder {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #ccc;
}

section#meusDados :-moz-placeholder,
section#registrarDoacao :-moz-placeholder {
  /* Firefox 18- */
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #ccc;
}

section#meusDados ::-moz-placeholder,
section#registrarDoacao ::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #ccc;
}

section#meusDados :-ms-input-placeholder,
section#registrarDoacao :-ms-input-placeholder {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #ccc;
}

section#registrarDoacao .text-danger {
  color: #f5f5f5;
}

.nomeDoador {
  color: #fff;
  text-align: right;
  padding: 5px 0px 10px 0px;
  font-weight: bold;
}

.container {
  width: 1250px;
}

.container-cadastro {
  width: 100%;
}

.view-animate-container {
  position: relative;
  height: 100px !important;
  background: white;
  height: 40px;
  overflow: hidden;
}

.view-animate {
  padding: 10px;
}

.view-animate.ng-enter,
.view-animate.ng-leave {
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.7s;

  display: block;
  width: 100%;

  position: absolute;
}

.view-animate.ng-enter {
  left: 100%;
}

.view-animate.ng-enter.ng-enter-active {
  left: 0;
}

.view-animate.ng-leave.ng-leave-active {
  left: -100%;
}

.adm-forms {
  position: relative;
  top: 100px;
}

.popover {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  margin-left: -90px;
}

.popover[uib-popover-popup],
.popover[uib-popover-html-popup],
.popover[uib-popover-template-popup] {
  display: block !important;
  width: 250px;
  margin-right: -233px;
}

.popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #bf090d;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
  color: white;
}

.popover.bottom > .arrow {
  top: -11px;
  left: 90%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.form-control:hover,
.form-control:active,
.form-control:focus {
  border-color: #bf090d;
  box-shadow: none;
}

.modal-body {
  position: relative;
  padding: 15px;
  background-color: #bf090d;
  color: white;
  font-weight: bold;
  text-align: justify;
}

.modal-body-card-modal {
  position: relative;
  font-weight: bold;
  text-align: justify;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #bf090d;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #bf090d;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #bf090d;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #bf090d;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #bf090d;
  border-color: #bf090d;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  z-index: 2;
  color: #bf090d;
  background-color: #eee;
  border-color: #bf090d;
}

.titulo-secao {
  padding-top: 30px;
  padding-bottom: 30px;
}

.titulo {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.texto {
  text-align: justify;
}

.cg-busy-default-wrapper {
  background-color: rgba(0, 0, 0, 0.25);
}

.cg-busy-default-text {
  font-size: 2em;
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
}

.autocomplete-list ul li.autocomplete-active {
  background-color: #bf090d !important;
}

.form-item-container .list-inline li span {
  border: 1px solid #bf090d !important;
  border-radius: 5px;
  padding: 4px;
}

.aceite {
  color: white;
  font-weight: bold;
  text-align: justify;
}

.aceite:focus,
.aceite:hover {
  color: black;
  text-decoration: underline;
}

.cabecalho {
  /*background-color: rgba(0, 0, 0, 0.1);*/
  width: 728px;
  height: 90px;
  margin-left: 45%;
  margin-bottom: -35px;
}

.texto_cabecalho {
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  color: white;
  padding-top: 20px;
}

.video {
  top: 230px;
  /*background-color: rgba(0, 0, 0, 0.1);*/
  width: 700px;
  height: 320px;
  position: absolute;
  left: 50%;
  margin-left: -350px;
  z-index: 2;
}

.fb-page {
  margin-top: 60px;
}

.action {
  bottom: 0px;
  right: 0px;
  position: fixed;
  z-index: 99999;
}

.action .btn-xl {
  border-radius: 5px;
}

.btn.btn-danger.btn-radio {
  color: black;
  background-color: white;
  margin-bottom: 10px;
}

.btn.btn-danger.btn-radio.active {
  color: white;
  background-color: #bf090d;
  border-color: #bf090d;
  font-weight: bold;
}

.badge {
  background-color: #bf090d;
  color: white;
  font-size: 20px;
  margin: 12px;
}

.logo-escoteiros {
  height: 100px;
  width: 170px;
}

#dias-visivel {
  font-size: 18px;
  font-weight: bold;
}

.carousel {
  height: 250px;
}

.carousel.slide {
  max-height: 150px;
  overflow-x: hidden;
  width: 100%;
  margin-bottom: 50px;
}

.carousel-control.right {
  max-width: 1px;
}

.num-doadores {
  padding-top: 100px;
  padding-bottom: 50px;
  opacity: 1;
}

.num-doadores .text-doadores {
  font-family: "Lobster", cursive;
  font-weight: 700;
  font-size: 70px;
  line-height: 90px;
  margin-bottom: 10px;
  margin-top: 0;
  color: #fff;
}

.campanha-modal {
  width: 100% !important;
  padding-right: 0 !important;
}

/* Slim-scroll */
.slimScroll {
  margin-top: 10px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  height: 150px; /* set this height in percentage to test resizing */
}

/* custom stylings */
.wrapper,
.scroll-wrapper {
  padding: 5px;
}
.scroll-wrapper.mac + .scrollBarContainer {
  background-color: transparent;
}
.scrollBarContainer.animate .scroll {
  transition: 2s opacity ease;
  -ms-transition: 2s opacity ease;
  opacity: 0;
}
.scrollBarContainer:hover .scroll {
  opacity: 0.5;
  transition: none;
  -ms-transition: none;
}
.unselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

.scroll {
  border-radius: 10px;
}
.wrapper + .scrollBarContainer {
  background-color: #eee9ee;
}
.scrollBar {
  background-color: #60f509;
  border-radius: 10px;
  opacity: 0.5;
}
.scroll-bar {
  background-color: #14d1e0;
}

.campanha-flex {
  display: flex !important;
  flex-wrap: wrap !important;
}

.img-fit-contain {
  object-fit: contain;
}


/*medias*/
@media (min-width: 992px) {
  .bg-light-gray {
    background-image: url("../img/fundo-site-escuro.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    -o-background-size: 100% 100%;
    text-align: center;
    color: #bf090d;
    z-index: -1;
    padding-bottom: 120px;
    image-size: 80%;
  }

  .bg-light-gray2 {
    background-image: url("../img/fundo-site-claro.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    -o-background-size: 100% 100%;
    text-align: center;
    color: #bf090d;
    z-index: -1;
    padding-bottom: 120px;
    image-size: 80%;
    opacity: 0.95;
  }

  section#contact {
    background-image: url("../img/fundo-site-escuro.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    -o-background-size: 100% 100%;
    color: #bf090d;
    z-index: -1;
    padding-bottom: 120px;
    image-size: 80%;
  }
}

@media screen and (max-width: 736px) {
  .bg-light-gray {
    background-image: url("../img/fundo-site-escuro-invertido.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    -o-background-size: 100% 100%;
    text-align: center;
    color: #bf090d;
    z-index: -1;
    padding-bottom: 120px;
    image-size: 80%;
  }

  .bg-light-gray2 {
    background-image: url("../img/fundo-site-claro-invertido.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    -o-background-size: 100% 100%;
    text-align: center;
    color: #bf090d;
    z-index: -1;
    padding-bottom: 120px;
    image-size: 80%;
    opacity: 0.95;
  }

  section#contact {
    background-image: url("../img/fundo-site-escuro-invertido.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    -o-background-size: 100% 100%;
    color: #bf090d;
    z-index: -1;
    padding-bottom: 120px;
    image-size: 80%;
  }
}



@media (min-width: 768px) {
  .navbar-custom {
    /*https://api.partiudoarsangue.com.br*/
    background-color: transparent !important;
    padding: 20px 0;
    -webkit-transition: padding 0.3s;
    -moz-transition: padding 0.3s;
    transition: padding 0.3s;
    border: none;
    height: 170px;
  }

  .admin {
    /*https://api.partiudoarsangue.com.br*/
    background-color: #000 !important;
    padding: 20px 0;
    -webkit-transition: padding 0.3s;
    -moz-transition: padding 0.3s;
    transition: padding 0.3s;
    border: none;
    height: 170px;
  }

  .navbar-custom .navbar-brand {
    font-size: 2em;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  .navbar-custom .navbar-nav > .active > a {
    border-radius: 5px;
  }
}

@media (min-width: 768px) {
  .navbar-custom.affix {
    background-image: url("../img/fundo-site-escuro.png");
    background-color: #fff;
    padding: 22px 0;
  }

  .navbar-custom.affix .navbar-brand {
    font-size: 15.5em;
  }
}

@media (min-width: 768px) {
  header .intro-text {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  header .intro-text .intro-lead-in {
    font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 25px;
    margin-top: 70px;
  }

  header .intro-text .intro-heading {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 30px;
    line-height: 60px;
    margin-bottom: 10px;
    margin-top: 140px;
  }
}

@media (min-width: 768px) {
  section {
    padding: 80px 0;
  }
}


@media (min-width: 767px) {
  #campanha .campanha-item {
    margin: 0 0 30px;
  }
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }

  .timeline > li {
    margin-bottom: 100px;
    min-height: 100px;
  }

  .timeline > li .timeline-panel {
    width: 41%;
    float: left;
    padding: 0 20px 20px 30px;
    text-align: right;
  }

  .timeline > li .timeline-image {
    width: 100px;
    height: 100px;
    left: 50%;
    margin-left: -50px;
  }

  .timeline > li .timeline-image h4 {
    font-size: 13px;
    margin-top: 16px;
    line-height: 18px;
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    text-align: left;
    padding: 0 30px 20px 20px;
  }
}

@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
  }

  .timeline > li .timeline-panel {
    padding: 0 20px 20px 100px;
  }

  .timeline > li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }

  .timeline > li .timeline-image h4 {
    margin-top: 40px;
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

/*telas 320 x 480*/
@media (min-width: 300px) and (max-width: 479px) {
  .action {
    display: none;
  }

  section h2.section-heading {
    font-size: 30px;
  }

  .btn-small {
    margin-left: 15px;
  }

  .video {
    top: 200px;
    max-width: 320px !important;
    height: 320px;
    position: absolute;
    margin-bottom: 20px;
    margin-left: -160px;
    left: 0%;
    z-index: 2;
  }

  .cabecalho {
    /*background-color: #0000001a;*/
    width: 90%;
    height: 90px;
    margin-left: 5%;
  }
  .texto_cabecalho {
    padding-top: 5px;
  }

  #logo {
    position: absolute;
    top: 90px;
    width: 75%;
  }

  #logo img {
    width: 35% !important;
    height: 35% !important;
    margin-top: 10px;
  }

  #logo_adm {
    position: absolute;
    top: 12px;
  }

  #logo_adm img {
    width: 35% !important;
    height: auto !important;
    margin-top: 40px;
    margin-left: 5%;
  }

  #imagem-header {
    background-image: url("../img/fundo-site-escuro-invertido.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    -o-background-size: 100% 100%;
    text-align: center;
    color: #bf090d;
    z-index: -1;
  }

  header .intro-text {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  header .intro-text .intro-lead-in {
    font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 25px;
    margin-top: 70px;
  }

  header .intro-text .intro-heading {
    margin-top: 160px;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 25px;
    height: 5px;
  }

  .main {
    margin-top: 80px;
    z-index: 1;
    margin-bottom: 30px;
  }

  .container-fluid > .navbar-collapse,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container > .navbar-header {
    margin-right: -25px;
    margin-left: -25px;
    background-image: url("../img/fundo-site-escuro.png");
    min-height: 80px;
  }

  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 45px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 5px;
  }

  section#meusDados .navbar-custom .nav li a {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff;
    font-size: 14px;
    padding: 5px 15px;
  }

  .container {
    width: 100%;
  }

  section#contact .form-group input.form-control,
  section#depoimentos .form-group input.form-control {
    height: 45px;
    width: 98%;
  }

  section#contact .form-group textarea.form-control,
  section#depoimentos .form-group textarea.form-control {
    height: 200px;
    width: 98%;
    -webkit-column-width: 320px;
  }

  section#depoimentos .form-group input,
  section#depoimentos .form-group select {
    padding: 10px;
    height: 45px;
    width: 98%;
    -webkit-column-width: 320px;
  }

  section#depoimentos .col-md-6 {
    padding-left: 25px;
  }

  .orientacoes {
    padding-left: 40px;
    padding-right: 35px;
    padding-bottom: 35px;
  }

  .team-member img {
    margin: 0 auto;
    border: 0px solid white;
    max-width: 80px;
    max-height: 120px;
  }

  section#parceiros .col-md-3 {
    width: 20%;
    margin-left: 30%;
  }

  .img-responsive {
    display: block;
    max-width: 93%;
    height: inherit;
    max-height: 93%;
    margin-bottom: 20px;
  }

  section#parceiros .img-responsive,
  section#parceiros .logo-escoteiros {
    display: block;
    max-width: 300%;
    height: inherit;
    max-height: 300%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .carousel {
    width: 320px;
    background-color: #fff;
  }

  .carousel-inner .col-md-8 {
    margin-top: 25px;
    width: 310px;
  }

  .carousel-control.right {
    right: 0px;
    left: auto;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(0, 0, 0, 0.0001)),
      to(rgba(0, 0, 0, 0.5))
    );
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0, rgba(251, 246, 246, 0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
    background-repeat: repeat-x;
  }

  .carousel img {
    width: 80px;
    height: 80px;
    margin-top: 25px;
    margin-left: 115px;
  }

  .carousel-indicators {
    display: none;
  }

  .smartphone {
    background: url("../img/smartphone-preto3-min.png") center center no-repeat scroll;
    background-size: 150%;
    padding: 177px;
    padding-top: 150px;
    margin-top: -45px;
    margin-left: -15px;
  }

  .img-app img {
    width: 150px;
    height: 60px;
    margin-bottom: -20px;
  }

  .form-custom-pds {
    background-color: #bf090d;
    background-image: none;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    display: none;
    background-size: 700px;
  }

  .nomeDoador {
    text-align: right;
    padding: 5px 15px 10px 0px;
    font-weight: bold;
    color: white;
    margin-right: -23px;
    margin-left: -25px;
    background-color: #ccc;
  }

  section#meusDados .container,
  section#registrarDoacao .container,
  section#minhasDoacoes .container,
  section#solicitacoesDoacao .container {
    width: 90%;
  }

  section#meusDados .form-group input.form-control,
  section#registrarDoacao .form-group input.form-control {
    height: 35px;
    width: 90%;
    margin: 0;
  }

  section#meusDados .form-group input,
  section#meusDados .form-group select,
  section#registrarDoacao .form-group input,
  section#registrarDoacao .form-group select {
    padding: 8px;
    height: 35px;
    width: 90%;
  }

  section#meusDados .col-md-6,
  section#registrarDoacao .col-md-6 {
    margin-left: 25px;
    width: 100%;
  }

  section#meusDados .row,
  section#registrarDoacao .row,
  section#solicitacoesDoacao .row {
    margin-right: -15px;
    margin-left: -30px;
  }

  section#meusDados .btn-xl {
    display: inline-block;
    margin-left: 80px;
  }

  section#meusDados,
  section#registrarDoacao,
  section#minhasDoacoes,
  section#solicitacoesDoacao {
    background-image: url("../img/fundo-site-claro.png");
    padding: 15px 5px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    -o-background-size: 100% 100%;
  }

  section#registrarDoacao .btn-xl {
    display: inline-block;
    margin-left: 50px;
  }

  section#registrarDoacao .form-group input[type="checkbox"],
  section#meusDados .form-group input[type="checkbox"] {
    transform: scale(1.5);
    margin-left: -40%;
    margin-right: -40%;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .show-doacoes,
  .show-solicita-doacao {
    background-color: #fff;
    /*background-image: -webkit-linear-gradient(left,rgba(204,204,204,0.45) 0,rgba(204,204,204,0.45) 100%);
        background-image: -o-linear-gradient(left,rgba(204,204,204,0.45) 0,rgba(204,204,204,0.45) 100%);
        background-image: -webkit-gradient(linear,left top,right top,from(rgba(204,204,204,0.45)),to(rgba(204,204,204,0.45)));
        background-image: linear-gradient(to right,rgba(204,204,204,0.45) 0,rgba(204,204,204,0.45) 100%);*/
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 20px;
    background-position: top right;
    opacity: 0.85;
  }

  .show-doacoes,
  .show-solicita-doacao {
    display: table;
    width: 100%;
  }

  .show-doacoes .titulo,
  .show-solicita-doacao .titulo {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .show-doacoes img {
    position: relative;
    float: right;
    margin-top: -265px;
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .show-solicita-doacao img {
    display: none;
  }

  .show-solicita-doacao .row {
    margin-right: -15px;
    margin-left: -30px;
  }

  .btn-xl {
    margin-top: 10px;
  }

  .popover {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    margin-left: 30%;
  }

  .popover.bottom > .arrow {
    top: -11px;
    left: 10%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, 0.25);
  }

  .cg-busy-default-text {
    font-size: 2em;
    color: white;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 30%;
  }

  .multiSelect .checkboxLayer {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    display: none !important;
    position: absolute;
    width: 92%;
    z-index: 999;
  }

  .navbar-custom.affix {
    background-image: url("../img/fundo-site-escuro.png");
    background-color: #fff;
    padding: 22px 0;
  }

  .navbar-custom.affix .navbar-brand {
    font-size: 15.5em;
  }

  .num-doadores .text-doadores {
    font-family: "Lobster", cursive;
    font-weight: 700;
    font-size: 60px !important;
    line-height: 90px;
    margin-bottom: 10px;
    margin-top: 0;
    color: #fff;
  }
}



/*Telas cadastro de doador*/

@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }

  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }

  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }

  .timeline > li .timeline-image h4 {
    font-size: 18px;
    margin-top: 30px;
    line-height: 26px;
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}

.pds-header-title {
  display: flex;
  width: 100%;
  justify-content: center;
  height: auto;
  padding-top: 150px;
}


section#pre-render {
  position: absolute;
  left: -9999px;
}


section.crowdfunding {
  height: 460px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

section.crowdfunding .crowdfunding-banner {
  background-image: url("../img/imagem-benfeitoria.png");
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  position: absolute;
  top: 0;
  filter: blur(4px);
}

section.crowdfunding .crowdfunding-content {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #bf090d;
}

section.crowdfunding .crowdfunding-content .crowdfunding-title {
  text-align: center;
  margin-top: 30px;
}

section.crowdfunding .crowdfunding-content .crowdfunding-title h2 {
  font-weight: bold;
}

section.crowdfunding .crowdfunding-content .crowdfunding-subtitle {
  text-align: center;
  margin-top: 30px;
  filter: drop-shadow(1px 2px 4px #ddd);
}

section.crowdfunding .crowdfunding-content .crowdfunding-subtitle h3 {
  font-size: 22px;
  font-weight: 600;
  text-transform: lowercase;
}

section.crowdfunding .crowdfunding-action {
  z-index: 2;
  position: absolute;
  bottom: 80px;
}

@media (max-width: 768px) {
  .action {
    display: none;
  }

  section h2.section-heading {
    font-size: 30px;
  }

  .btn-small {
    margin-left: 15px;
  }

  .video {
    top: 200px;
    max-width: 320px !important;
    height: 320px;
    position: absolute;
    margin-bottom: 20px;
    margin-left: -160px;
    left: 0%;
    z-index: 2;
  }

  .cabecalho {
    /*background-color: #0000001a;*/
    width: 90%;
    height: 90px;
    margin-left: 5%;
  }
  .texto_cabecalho {
    padding-top: 5px;
  }

  #logo {
    position: absolute;
    top: 15px;
    width: 75%;
  }

  #logo img {
    height: 100%;
    max-height: 80px;
  }

  #logo_adm {
    position: absolute;
    top: 12px;
  }

  #logo_adm img {
    width: 35% !important;
    height: auto !important;
    margin-top: 40px;
    margin-left: 5%;
  }

  #imagem-header {
    background-image: url("../img/fundo-site-escuro-invertido.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    -o-background-size: 100% 100%;
    text-align: center;
    color: #bf090d;
    z-index: -1;
  }

  header .intro-text {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  header .intro-text .intro-lead-in {
    font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 25px;
    margin-top: 70px;
  }

  header .intro-text .intro-heading {
    margin-top: 160px;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 25px;
    height: 5px;
  }

  .main {
    margin-top: 80px;
    z-index: 1;
    margin-bottom: 30px;
  }

  .container-fluid > .navbar-collapse,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container > .navbar-header {
    margin-right: -25px;
    margin-left: -25px;
    background-image: url("../img/fundo-site-escuro.png");
    min-height: 80px;
  }

  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 45px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 5px;
  }

  section#meusDados .navbar-custom .nav li a {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff;
    font-size: 14px;
    padding: 5px 15px;
  }

  .container {
    width: 100%;
  }

  section#contact .form-group input.form-control,
  section#depoimentos .form-group input.form-control {
    height: 45px;
    width: 98%;
  }

  section#contact .form-group textarea.form-control,
  section#depoimentos .form-group textarea.form-control {
    height: 200px;
    width: 98%;
    -webkit-column-width: 320px;
  }

  section#depoimentos .form-group input,
  section#depoimentos .form-group select {
    padding: 10px;
    height: 45px;
    width: 98%;
    -webkit-column-width: 320px;
  }

  section#depoimentos .col-md-6 {
    padding-left: 25px;
  }

  .orientacoes {
    padding-left: 40px;
    padding-right: 35px;
    padding-bottom: 35px;
  }

  .team-member img {
    margin: 0 auto;
    border: 0px solid white;
    max-width: 80px;
    max-height: 120px;
  }

  section#parceiros .col-md-3 {
    width: 20%;
    margin-left: 30%;
  }

  .img-responsive {
    display: block;
    max-width: 93%;
    height: inherit;
    max-height: 93%;
    margin-bottom: 20px;
  }

  section#parceiros .img-responsive,
  section#parceiros .logo-escoteiros {
    display: block;
    max-width: 300%;
    height: inherit;
    max-height: 300%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .carousel {
    width: 320px;
    background-color: #fff;
  }

  .carousel-inner .col-md-8 {
    margin-top: 25px;
    width: 310px;
  }

  .carousel-control.right {
    right: 0px;
    left: auto;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(0, 0, 0, 0.0001)),
      to(rgba(0, 0, 0, 0.5))
    );
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0, rgba(251, 246, 246, 0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
    background-repeat: repeat-x;
  }

  .carousel img {
    width: 80px;
    height: 80px;
    margin-top: 25px;
    margin-left: 115px;
  }

  .carousel-indicators {
    display: none;
  }

  .smartphone {
    background: url("../img/smartphone-preto3-min.png") center center no-repeat scroll;
    background-size: 150%;
    padding: 177px;
    padding-top: 150px;
    margin-top: -45px;
    margin-left: -15px;
  }

  .img-app img {
    width: 150px;
    height: 60px;
    margin-bottom: -20px;
  }

  .form-custom-pds {
    background-color: #bf090d;
    background-image: none;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    display: none;
    background-size: 700px;
  }

  .nomeDoador {
    text-align: right;
    padding: 5px 15px 10px 0px;
    font-weight: bold;
    color: white;
    margin-right: -23px;
    margin-left: -25px;
    background-color: #ccc;
  }

  section#meusDados .container,
  section#registrarDoacao .container,
  section#minhasDoacoes .container,
  section#solicitacoesDoacao .container {
    width: 90%;
  }

  section#meusDados .form-group input.form-control,
  section#registrarDoacao .form-group input.form-control {
    height: 35px;
    width: 90%;
    margin: 0;
  }

  section#meusDados .form-group input,
  section#meusDados .form-group select,
  section#registrarDoacao .form-group input,
  section#registrarDoacao .form-group select {
    padding: 8px;
    height: 35px;
    width: 90%;
  }

  section#meusDados .col-md-6,
  section#registrarDoacao .col-md-6 {
    margin-left: 25px;
    width: 100%;
  }

  section#meusDados .row,
  section#registrarDoacao .row,
  section#solicitacoesDoacao .row {
    margin-right: -15px;
    margin-left: -30px;
  }

  section#meusDados .btn-xl {
    display: inline-block;
    margin-left: 80px;
  }

  section#meusDados,
  section#registrarDoacao,
  section#minhasDoacoes,
  section#solicitacoesDoacao {
    background-image: url("../img/fundo-site-claro.png");
    padding: 15px 5px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    -o-background-size: 100% 100%;
  }

  section#registrarDoacao .btn-xl {
    display: inline-block;
    margin-left: 50px;
  }

  section#registrarDoacao .form-group input[type="checkbox"],
  section#meusDados .form-group input[type="checkbox"] {
    transform: scale(1.5);
    margin-left: -40%;
    margin-right: -40%;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .show-doacoes,
  .show-solicita-doacao {
    background-color: #fff;
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 20px;
    background-position: top right;
    opacity: 0.85;
  }

  .show-doacoes,
  .show-solicita-doacao {
    display: table;
    width: 100%;
  }

  .show-doacoes .titulo,
  .show-solicita-doacao .titulo {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .show-doacoes img {
    position: relative;
    float: right;
    margin-top: -265px;
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .show-solicita-doacao img {
    display: none;
  }

  .show-solicita-doacao .row {
    margin-right: -15px;
    margin-left: -30px;
  }

  .btn-xl {
    margin-top: 10px;
  }

  .popover {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    margin-left: 30%;
  }

  .popover.bottom > .arrow {
    top: -11px;
    left: 10%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, 0.25);
  }

  .cg-busy-default-text {
    font-size: 2em;
    color: white;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 30%;
  }

  .multiSelect .checkboxLayer {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    display: none !important;
    position: absolute;
    width: 92%;
    z-index: 999;
  }

  .navbar-custom.affix {
    background-image: url("../img/fundo-site-escuro.png");
    background-color: #fff;
    padding: 22px 0;
  }

  .navbar-custom.affix .navbar-brand {
    font-size: 15.5em;
  }

  .num-doadores .text-doadores {
    font-family: "Lobster", cursive;
    font-weight: 700;
    font-size: 60px !important;
    line-height: 90px;
    margin-bottom: 10px;
    margin-top: 0;
    color: #fff;
  }

  .btn.btn-xl {
    width: 100%;
    font-size: 16px;
    max-width: 360px;
  }

  section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    padding-top: 20px;
    margin: 0;
  }

  section#novoDoador {
    display: none !important;
  }

  section#campanha .btn.btn-xl {
    margin: 0;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  section#saiba-mais {
    padding: 20px;
  }

  section#onde-doar .btn.btn-pesquisa-onde-doar {
    margin-bottom: 20px;
  }

  .navbar-toggle {
    margin: 0;
    margin-top: 20px;
    margin-right: 20px;
  }

  .num-doadores {
    padding: 0;
  }

  .num-doadores .text-doadores {
    font-size: 60px !important;
  }

  #imagem-header {
    max-height: 980px;
  }

  section.crowdfunding {
    height: 400px;
  }

  section.crowdfunding .crowdfunding-content .crowdfunding-title h2 {
    font-size: 22px;
  }

  section.crowdfunding .crowdfunding-content .crowdfunding-subtitle h3 {
    font-size: 16px;
  }

}
